<template>
  <v-row dense>
    <v-col cols="12" sm="6" md="4">
      <c-input v-model="value.user.firstName" :label="$t('shared.firstName')" :rules="'required|max:30'" dir="auto" />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <c-input v-model="value.user.lastName" :label="$t('shared.lastName')" dir="auto" :rules="'required|max:30'" />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <c-input v-model="value.user.email" :label="$t('shared.email')" dir="ltr" :rules="{required: !value.user.mobile, email: true}" />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <mobile-input
        v-model="value.user.mobile"
        :code.sync="value.user.countryCode"
        dir="ltr"
        :label="$t('shared.mobile')"
        :rules="{required: !value.user.email, numeric: true}"
      />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <c-input v-model="value.password" type="password" :label="$t('users.password')" dir="ltr" :rules="'min:6|max:30'" />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <c-input
        v-model="value.passwordConfirm"
        :label="$t('users.passwordConfirm')"
        dir="ltr"
        type="password"
        :rules="`min:6|confirmed:${$t('users.password')}|max:30`"
      />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <c-input v-model="value.profile.skinType" :label="$t('users.skinType')" :rules="'numeric|between:0,6'" dir="auto" />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <c-input select v-model="value.profile.gender" :label="$t('users.gender')" :rules="'required'" :items="genderItems" />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <date-picker v-model="value.profile.birthDate" :label="$t('users.birthDate')" />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <uploader v-model="value.profile.image" :label="$t('shared.image')" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'BaseForm',
  props: {
    value: {type: Object, default: () => ({})}
  },
  data() {
    return {
      genderItems: [
        {text: this.$t('shared.male'), value: 'MALE'},
        {text: this.$t('shared.female'), value: 'FEMALE'}
      ]
    }
  }
}
</script>
