<template>
  <v-card>
    <v-overlay absolute :value="progressing" opacity="0.3">
      <v-progress-circular indeterminate color="primary" class="mb-0" size="64"></v-progress-circular>
    </v-overlay>

    <v-card-title class="text-h5">{{ $t('nav.workingSites') }}</v-card-title>
    <v-card-text>
      <search-form>
        <div class="d-inline-flex flex-wrap mb-3">
          <c-input v-model="search.id" label="ID" search-form />

          <c-input v-model="search.name" :label="$t('shared.name')" search-form />

          <auto-complete
            v-if="!branchId"
            v-model="search.organizationId"
            :search="organizationSearch"
            :label="$t('organizations.organization')"
            search-form
          />

          <c-input select v-model="search.active" :items="$booleanOptions" :label="$t('shared.active')" search-form />
          <date-picker v-model="search.created.min" search-form :label="$t('shared.createdMin')" />

          <date-picker v-model="search.created.max" search-form :label="$t('shared.createdMax')" />

          <c-input v-model="search.tel" :label="$t('shared.phone')" search-form />
          <c-input v-model="search.fax" :label="$t('shared.fax')" search-form />
          <c-input v-model="search.address" :label="$t('shared.address')" search-form />
        </div>

        <div class="ma-1">
          <v-btn color="success" dark @click="filtering" small :loading="progressing">
            <v-icon small class="me-1"> mdi-filter </v-icon>
            {{ $t('shared.applyFilter') }}
          </v-btn>
        </div>
      </search-form>
    </v-card-text>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="context"
      @update:options="filtering"
      :server-items-length="count"
      :loading="progressing"
      :footer-props="$config.tableFooterProps"
      sort-by="id"
      must-sort
      class="elevation-0 mb-0"
    >
      <template #[`item.active`]="{item}">
        <v-icon v-if="item.active" color="success"> mdi-check </v-icon>
        <v-icon v-else color="secondary"> mdi-close </v-icon>
      </template>

      <template #[`item.actions`]="{item}">
        <div class="d-flex justify-end">
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-btn small fab text color="info" :to="`/working-sites/edit/${item.id}`" v-on="on">
                <v-icon> mdi-eye </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('shared.view') }}</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:no-data>
        {{ $t('shared.noRecords') }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'Index',
  props: {
    branchId: {type: [String, Number], default: null},
    userId: {type: [String, Number], default: null}
  },
  data() {
    return {
      progressing: false,
      count: 1,
      context: {},
      items: [],
      headers: [
        {text: 'ID', value: 'id'},
        {text: this.$t('shared.name'), value: 'name'},
        {text: this.$t('shared.actions'), value: 'actions', sortable: false}
      ],
      search: {
        id: null,
        name: null,
        active: null,
        tel: null,
        fax: null,
        address: null,
        created: {
          min: null,
          max: null
        }
      }
    }
  },
  methods: {
    organizationSearch(filter) {
      return this.$api.organizations.getAllInfo(filter)
    },
    filter(isCount) {
      const filter = this.$filter()
      if (this.branchId) {
        filter.organizationId = this.branchId
      }
      if (this.userId) {
        filter.userId = this.userId
      }
      if (!isCount) {
        filter['sort[0].column'] = this.context.sortBy[0]
        filter['sort[0].type'] = this.context.sortDesc[0] ? 'ASCENDING' : 'DESCENDING'
        filter.size = this.context.itemsPerPage
        filter.page = this.context.page
      }

      return filter
    },
    getItems(filter) {
      return this.$api.workingSites.getAll(filter, this.type).then((res) => {
        this.items = res
      })
    },
    getCount(filter) {
      return this.$api.workingSites.count(filter, this.type).then((res) => {
        this.count = res
      })
    },
    filtering() {
      this.progressing = true
      Promise.all([this.getItems(this.filter()), this.getCount(this.filter(true))])
        .then(() => {
          this.progressing = false
        })
        .catch((err) => {
          this.$showError(err)
        })
    }
  }
}
</script>
