<script>
import BaseForm from './components/BaseForm'
export default {
  name: 'EditForm',
  components: {
    BaseForm
  },
  data() {
    return {
      progressing: false,
      initializing: true,
      model: {
        password: null,
        passwordConfirm: null,
        profile: {
          birthDate: null,
          gender: null,
          image: null
        },
        user: {
          email: null,
          firstName: null,
          lastName: null,
          mobile: null,
          countryCode: 'AU',
          twoFactorEnabled: false
        }
      }
    }
  }
}
</script>
